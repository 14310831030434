<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <strong>
          </strong> Partners
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details

          ></v-text-field>
        </v-card-title>
        <v-btn
            color="blue-grey"
            class="ma-2 white--text"
            @click="addPartner"
        >
          Add New Partner
          <v-icon
              right
              dark
          >
            mdi-plus
          </v-icon>
        </v-btn>
<v-col></v-col>
        <v-data-table
            :headers="headers"
            :items="partners"
            item-key="id"
            class="elevation-1 table-one"
            multi-sort
            :search="search"
            v-model="selectedRows"
            show-select
        >
          <template v-slot:item.name="{item}">
            <div class="d-flex align-center">
              <p class="ma-0 font-weight-medium">
                {{ item.name }}
              </p>
            </div>
          </template>
          <template v-slot:item.action="{item}">
            <div class="d-flex">

                <v-btn
                    color="green"
                    dark
                    outlined
                    @click.stop="viewPartner(item.id)">
                  View
                </v-btn>
                <v-btn
                    color="red"
                    dark
                    outlined
                    @click="delRecord(item.id)">
                  Delete
                </v-btn>
            </div>
          </template>
        </v-data-table>
      </base-card>
    </v-col>
    <AddPartnerDialog ref="addPartnerDialog" @setPartner="addPartner" />
    <ConfirmDialog ref="confirmDelete" />
  </v-row>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import { saveAs } from 'file-saver';
import AddPartnerDialog from "@/components/shared/AddPartnerDialog.vue";
import toastr from "toastr";


export default {
  name: "Partners",
  components: {
    AddPartnerDialog,
    ConfirmDialog,
  },
  data() {
    return {
      search: '',
      items: [],
      selectedRows: [],
      headers: [
        {
          text: 'Partner Name',
          sortable: true,
          value: 'name',
          align: 'left',
        },
        {
          text: 'Phone',
          sortable: true,
          value: 'phone',
          align: 'left',
        },
        {
          text: 'Email',
          sortable: true,
          value: 'email',
          align: 'left',
        },
        {
          text: 'Url',
          sortable: true,
          value: 'url',
          align: 'middle',
        },
        {
          text: 'Actions',
          value: 'action',
        }
      ],
    }
  },
  computed: {
    ...mapState('partners', {loadingPartners : 'isFindPending'}),
    ...mapGetters('partners', {findPartnersInStore : 'find'}),

    partners() {
      return this.findPartnersInStore({ query: {device_status: { $ne: 'stock' }, archived: { $ne: 1 }}}).data || [];
    },
    selectedPartnerIds() {
      return this.selectedRows.map(a => a.id);
    }
  },
  methods: {
    ...mapActions('partners', { findPartners: 'find' }),
    deleteItem(id) {
      this.$store.dispatch('partners/remove', id)
    },
    async delRecord(id) {
      if (
          await this.$refs.confirmDelete.open(
              "Confirm",
              "Are you sure you want to delete this record?"
          )
      ) {
        this.deleteItem(id);
      }
    },
    async addPartner(partner) {
      if (
          await this.$refs.addPartnerDialog.open(
              "Add Partner",
              "Please complete the form below to add a new Partner"
          )
      ) {
        this.$store.dispatch('partners/create', partner).then((result) => {
          toastr.success('Successfully created partner');
        }).catch((error) => {
          console.log(error)
          toastr.error(error);
        })
      }
    },
    viewPartner(id) {
      this.$router.push('/app/partnerview/'+id)
    },
    async getAssetList(){
      saveAs(await ( await fetch(process.env.VUE_APP_FEATHERS_WS_URL+'api/reports/assetList?fileName=emissisAssetList&partnerIds='+this.selectedPartnerIds, {
        method: 'GET',
        headers: {"Authorization": "Bearer " + this.$store.state.auth.accessToken}
      })).blob(), 'coolnomixAssetList.pdf');
    },
    async getOM(){
      saveAs(await ( await fetch(process.env.VUE_APP_FEATHERS_WS_URL+'api/reports/om?' + new URLSearchParams({
        customer: this.repLabelCustomer,
        site: this.repLabelSite,
        date: this.repLabelDate,
        fileName: 'coolnomixOM',
        partnerIds: this.selectedPartnerIds
      }), {
        method: 'GET',
        headers: {"Authorization": "Bearer " + this.$store.state.auth.accessToken}
      })).blob(), 'coolnomixOM.pdf');
    },
  },
  mounted() {
    this.findPartners();
    if (localStorage.partnersIndexSearch) {
      this.search = localStorage.partnersIndexSearch;
    }
  },
  watch: {
    search(newSearchTerm) {
      localStorage.partnersIndexSearch = newSearchTerm;
    }
  },
}
</script>

<style scoped>

</style>
